<template>
<div class="card card-custom gutter-b">
    <div class="card-body">
        <!--begin::Example-->
        <div class="example mb-10">
            <div style="background-color: #1BC5BD;" class="card card-custom">
                <div class="card-body text-green">
                    <h3 class="card-label text-green" style="color:white">
                        <i class="fa fa-info-circle" style="color:white;" aria-hidden="true"></i> Uji Petik
                    </h3><hr>
                    <p style="color:white; font-size:15px;">Silahkan anda memberikan konfirmasi terkait pelaksanaan uji petik untuk memperoleh berita acara dan rekomendasi (setelah tim penilai menyelesaikan semua tugas uji petik dan memberikan rekomendasi)</p>
                    <!-- <div>
                        <a v-if="user.id_role === 'ppb_kl'" :href="paparankl" target="_blank" class="btn btn-primary" style="float: right; background-color: #004d00; border-color: #004d00"><i class="fa fa-download" aria-hidden="true"/> Unduh</a>
                        <a v-else target="_blank" :href="paparanptsp" class="btn btn-primary" style="float: right; background-color: #004d00; border-color: #004d00"><i class="fa fa-download" aria-hidden="true"/> Unduh</a>
                    </div><br>
                    <p style="color:white; font-size:15px;">Selanjutnya unggah dokumen presentasi dan lampiran</p>
                    <div class="form-group row">
                        <label style="color:white; font-size:15px;" class="col-4">Unggah PPT (Maksimal 30 mb)</label>
                            <div class="col-3">
                                <b-form-file v-model="pemaparan.file_ppt_pemaparan" accept=".ppt, .pptx" @change="file_presentasi" id="fileppt" type="file" ref="file"></b-form-file>
                            </div>
                            <div class="col-2">
                                <button v-if="paparan === 'Ya'" type="button" @click="editpemaparanpresentasi(pemaparan.id)" style="float: right" class="btn btn-primary  text-uppercase"><i class="flaticon-upload"></i> Unggah</button>
                            </div>
                            <div class="col-1" v-if="pemaparan.fileppt != null">
                                <a target="_blank" :href="pemaparan.fileppt" class="btn btn-primary" style="background-color: #004d00; border-color: #004d00"><i class="fa fa-download" aria-hidden="true"/> Unduh</a>
                            </div>
                    </div>
                    <div class="form-group row">
                        <label style="color:white; font-size:15px;" class="col-lg-4 col-form-label">Unggah Lampiran (Maksimal 30 mb)</label>
                        <div class="col-lg-3">
                            <b-form-file v-model="pemaparan.file_lampiran_pemaparan" accept=".doc, .docx" @change="file_lampiran" id="filelampiran" type="file" ref="file"></b-form-file>
                        </div>
                        <div class="col-2">
                            <button v-if="paparan === 'Ya'" type="button" @click="editpemaparanlampiran(pemaparan.id)" style="float: right" class="btn btn-primary  text-uppercase"> <i class="flaticon-upload"></i>Unggah</button>
                        </div>
                        <div class="col-1" v-if="pemaparan.filelampiran != null">
                            <a target="_blank" :href="pemaparan.filelampiran" class="btn btn-primary" style="background-color: #004d00; border-color: #004d00"><i class="fa fa-download" aria-hidden="true"/> Unduh</a>
                        </div>
                    </div>
                    <p style="color:white; font-size:15px;">Setelah anda selesai melaksanakan Pemaparan silahkan download berita acara dibawah ini (setelah seluruh timpenilai menyelesaikan penilaian)</p>
                    <div>
                        <button class="btn btn-primary" @click="urlba()" style="float: right; background-color: #004d00; border-color: #004d00"><i class="fa fa-download" aria-hidden="true"/> Unduh BA</button>
                    </div> -->
                    <div class="row">
                        <div class="col-auto mr-auto" v-if="user.id_role === 'ppb_kl'">
                            <!-- <button v-if="ujipetik[0].file_ba_uji_petik === null" type="button" @click="persetujuan(ujipetik[0].idkl)" class="btn btn-primary"><i class="fa fa-check" style="color:white" aria-hidden="true"></i> Mengetahui</button> -->
                            <button type="button" @click="cekba()" style="background-color: #004d00; border-color: #004d00" class="btn btn-success"><i class="fa fa-download" aria-hidden="true"/> Unduh BA</button>
                            <button type="button" @click="cekrekomendasi()" style="background-color: #004d00; border-color: #004d00" class="btn btn-success ml-2"><i class="fa fa-eye" aria-hidden="true"/> Rekomendasi</button>
                        </div>
                        <div class="col-auto mr-auto" v-else>
                            <!-- <button v-if="ujipetik[0].file_ba_uji_petik === null" type="button" @click="persetujuan(ujipetik[0].idptsp)" class="btn btn-primary"><i class="fa fa-check" style="color:white" aria-hidden="true"></i> Mengetahui</button> -->
                            <button type="button" @click="cekba()" style="background-color: #004d00; border-color: #004d00" class="btn btn-success"><i class="fa fa-download" aria-hidden="true"/> Unduh BA</button>
                            <button type="button" @click="cekrekomendasi()" style="background-color: #004d00; border-color: #004d00" class="btn btn-success ml-2"><i class="fa fa-eye" aria-hidden="true"/> Rekomendasi</button>
                        </div>
                        <div class="col-auto"></div>
                    </div>
                </div>
            </div>
            <!-- <h4 class="mb-10 font-weight-bold text-dark">INFORMASI IDENTITAS PTSP Pemda</h4> -->
        <!--begin::Input-->
        <!-- <div class="form-group row">
            <label class="col-lg-4 col-form-label"></label>
            <div class="col-lg-8">
                <div class="row">
                    <div class="col-xl-12">
                       
                    </div>
                </div>
                <button type="button" @click="editpemaparan" style="background-color: #030A8C;float: right; border-color: #030A8C" class="btn btn-primary font-weight-bolder text-uppercase px-9 py-4">Simpan</button>
            </div>
        </div> -->
        </div>
        <!--end::Example-->
    </div>

    <!-- modal ubah rekomendasi -->
            <b-modal
                id="modal-editrekomendasi"
                ref="modal"
                size="xl"
                modal-class="test-modal"
                title="Data Rekomendasi"
                 scrollable
                :no-close-on-backdrop="true"
                >
                    <div class="row">
                        <div class="col-md-12">
                        <h5>A. Pelayanan Perizinan Berusaha</h5>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-12 ml-4">
                        <b> 1. Kelembagaan</b>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-12">
                            <div class="container">
                                <b-form-group
                                    label="Resume Hasil Uji Petik Nomine"
                                    label-for="name"
                                >
                                <div class="card text-black bg-secondary mb-3">
                                    <div class="card-body">
                                        <div class="card-text" v-html="datarekomendasi.resume_kelembagaan"></div>
                                    </div>
                                </div>
                                </b-form-group>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-12">
                            <div class="container">
                                <b-form-group
                                    label="Rekomendasi"
                                    label-for="name"
                                    class="mb-3"
                                >
                                    <div class="card text-black bg-secondary mb-3">
                                        <div class="card-body">
                                            <div class="card-text" v-html="datarekomendasi.rekomendasi_kelembagaan"></div>
                                        </div>
                                    </div>
                                </b-form-group>
                            </div>
                        </div>
                    </div>

                    <div class="row">
                        <div class="col-md-12 ml-4">
                        <b> 2. SDM </b>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-12">
                            <div class="container">
                                <b-form-group
                                    label="Resume Hasil Uji Petik Nomine"
                                    label-for="name"
                                    class="mb-3"
                                >
                                    <div class="card text-black bg-secondary mb-3">
                                        <div class="card-body">
                                            <div class="card-text" v-html="datarekomendasi.resume_sdm"></div>
                                        </div>
                                    </div>
                                </b-form-group>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-12">
                            <div class="container">
                                <b-form-group
                                    label="Rekomendasi"
                                    label-for="name"
                                    class="mb-3"
                                >
                                    <div class="card text-black bg-secondary mb-3">
                                        <div class="card-body">
                                            <div class="card-text" v-html="datarekomendasi.rekomendasi_sdm"></div>
                                        </div>
                                    </div>
                                </b-form-group>
                            </div>
                        </div>
                    </div>

                    <div class="row">
                        <div class="col-md-12 ml-4">
                        <b> 3. Sarpras</b>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-12">
                            <div class="container">
                                <b-form-group
                                    label="Resume Hasil Uji Petik Nomine"
                                    label-for="name"
                                    class="mb-3"
                                >
                                    <div class="card text-black bg-secondary mb-3">
                                        <div class="card-body">
                                            <div class="card-text" v-html="datarekomendasi.resume_sarpras"></div>
                                        </div>
                                    </div>
                                </b-form-group>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-12">
                            <div class="container">
                                <b-form-group
                                    label="Rekomendasi"
                                    label-for="name"
                                    class="mb-3"
                                >
                                    <div class="card text-black bg-secondary mb-3">
                                        <div class="card-body">
                                            <div class="card-text" v-html="datarekomendasi.rekomendasi_sarpras"></div>
                                        </div>
                                    </div>
                                </b-form-group>
                            </div>
                        </div>
                    </div>

                    <div class="row">
                        <div class="col-md-12 ml-4">
                        <b>4. Implementasi OSS</b>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-12">
                            <div class="container">
                                <b-form-group
                                    label="Resume Hasil Uji Petik Nomine"
                                    label-for="name"
                                    class="mb-3"
                                >
                                    <div class="card text-black bg-secondary mb-3">
                                        <div class="card-body">
                                            <div class="card-text" v-html="datarekomendasi.resume_implementasi_oss"></div>
                                        </div>
                                    </div>
                                </b-form-group>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-12">
                            <div class="container">
                                <b-form-group
                                    label="Rekomendasi"
                                    label-for="name"
                                    class="mb-3"
                                >
                                    <div class="card text-black bg-secondary mb-3">
                                        <div class="card-body">
                                            <div class="card-text" v-html="datarekomendasi.rekomendasi_implementasi_oss"></div>
                                        </div>
                                    </div>
                                </b-form-group>
                            </div>
                        </div>
                    </div>

                    <div class="row">
                        <div class="col-md-12 ml-4">
                        <b> 5. Capaian target-target </b>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-12">
                            <div class="container">
                                <b-form-group
                                    label="Resume Hasil Uji Petik"
                                    label-for="name"
                                    class="mb-3"
                                >
                                    <div class="card text-black bg-secondary mb-3">
                                        <div class="card-body">
                                            <div class="card-text" v-html="datarekomendasi.resume_target_capaian"></div>
                                        </div>
                                    </div>
                                </b-form-group>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-12">
                            <div class="container">
                                <b-form-group
                                    label="Rekomendasi"
                                    label-for="name"
                                    class="mb-3"
                                >
                                    <div class="card text-black bg-secondary mb-3">
                                        <div class="card-body">
                                            <div class="card-text" v-html="datarekomendasi.rekomendasi_target_capaian"></div>
                                        </div>
                                    </div>
                                </b-form-group>
                            </div>
                        </div>
                    </div>
                    <hr>
                    <div class="row">
                        <div class="col-md-12">
                        <h5> B. Percepatan Pelaksanaan Berusaha </h5>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-12 ml-4">
                        <b>  1. Peningkatan iklim berusaha </b>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-12">
                            <div class="container">
                                <b-form-group
                                    label="Resume Hasil Uji Petik Nomine"
                                    label-for="name"
                                    class="mb-3"
                                >
                                    <div class="card text-black bg-secondary mb-3">
                                        <div class="card-body">
                                            <div class="card-text" v-html="datarekomendasi.resume_peningkatan_iklim_berusaha"></div>
                                        </div>
                                    </div>
                                </b-form-group>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-12">
                            <div class="container">
                                <b-form-group
                                    label="Rekomendasi"
                                    label-for="name"
                                    class="mb-3"
                                >
                                    <div class="card text-black bg-secondary mb-3">
                                        <div class="card-body">
                                            <div class="card-text" v-html="datarekomendasi.rekomendasi_peningkatan_iklim_berusaha"></div>
                                        </div>
                                    </div>
                                </b-form-group>
                            </div>
                        </div>
                    </div>

                    <div class="row">
                        <div class="col-md-12 ml-4">
                        <b> 2. Tim pelaksana PPB </b>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-12">
                            <div class="container">
                                <b-form-group
                                    label="Resume Hasil Uji Petik Nomine"
                                    label-for="name"
                                    class="mb-3"
                                >
                                    <div class="card text-black bg-secondary mb-3">
                                        <div class="card-body">
                                            <div class="card-text" v-html="datarekomendasi.resume_organisasi_pelaksana_ppb"></div>
                                        </div>
                                    </div>
                                </b-form-group>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-12">
                            <div class="container">
                                <b-form-group
                                    label="Rekomendasi"
                                    label-for="name"
                                    class="mb-3"
                                >
                                    <div class="card text-black bg-secondary mb-3">
                                        <div class="card-body">
                                            <div class="card-text" v-html="datarekomendasi.rekomendasi_organisasi_pelaksana_ppb"></div>
                                        </div>
                                    </div> 
                                </b-form-group>
                            </div>
                        </div>
                    </div>

                    <div class="row">
                        <div class="col-md-12 ml-4">
                        <b> 3. Dukungan pimpinan daerah </b>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-12">
                            <div class="container">
                                <b-form-group
                                    label="Resume Hasil Uji Petik Nomine"
                                    label-for="name"
                                    class="mb-3"
                                >
                                    <div class="card text-black bg-secondary mb-3">
                                        <div class="card-body">
                                            <div class="card-text" v-html="datarekomendasi.resume_dukungan_pimpinan_daerah"></div>
                                        </div>
                                    </div>
                                </b-form-group>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-12">
                            <div class="container">
                                <b-form-group
                                    label="Rekomendasi"
                                    label-for="name"
                                    class="mb-3"
                                >
                                    <div class="card text-black bg-secondary mb-3">
                                        <div class="card-body">
                                            <div class="card-text" v-html="datarekomendasi.rekomendasi_dukungan_pimpinan_daerah"></div>
                                        </div>
                                    </div> 
                                </b-form-group>
                            </div>
                        </div>
                    </div>
                <template #modal-footer>
                    <div class="w-100">
                    <b-button
                        variant="primary"
                        size="sm"
                        class="float-right"
                        style="background-color: #030A8C; border-color: #030A8C; color:white"
                        @click="$bvModal.hide('modal-editrekomendasi')"
                    >
                        Tutup
                    </b-button>
                    </div>
                </template>
            </b-modal>

            <b-modal
                id="modal-editrekomendasikl"
                ref="modal"
                size="xl"
                modal-class="test-modal"
                title="Data Rekomendasi"
                 scrollable
                @show="resetModal"
                :no-close-on-backdrop="true"
                @ok="handleOk3"
                >
                <form ref="form3" @submit.stop.prevent="inputrekomendasi">
                    <div class="row">
                        <div class="col-md-12">
                        <h5>A. Kesesuaian Data dan Dokumen dengan Pelaksanaan</h5>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-12 ml-4">
                        <b> 1. Kelengkapan dasar hukum terkait perizinan berusaha (NSPK)</b>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-12">
                            <div class="container">
                                <b-form-group
                                    label="Resume Hasil Uji Petik Nomine"
                                    label-for="name"
                                >
                                    <div class="card text-black bg-secondary mb-3">
                                        <div class="card-body">
                                            <div class="card-text" v-html="datarekomendasi.resume_nspk"></div>
                                        </div>
                                    </div> 
                                </b-form-group>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-12">
                            <div class="container">
                                <b-form-group
                                    label="Rekomendasi"
                                    label-for="name"
                                    class="mb-3"
                                >
                                    <div class="card text-black bg-secondary mb-3">
                                        <div class="card-body">
                                            <div class="card-text" v-html="datarekomendasi.rekomendasi_nspk"></div>
                                        </div>
                                    </div> 
                                </b-form-group>
                            </div>
                        </div>
                    </div>

                    <div class="row">
                        <div class="col-md-12 ml-4">
                        <b> 2. Kelengkapan data dan dokumen pendukung serta pengarsipannya </b>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-12">
                            <div class="container">
                                <b-form-group
                                    label="Resume Hasil Uji Petik Nomine"
                                    label-for="name"
                                    class="mb-3"
                                >
                                    <div class="card text-black bg-secondary mb-3">
                                        <div class="card-body">
                                            <div class="card-text" v-html="datarekomendasi.resume_data_pendukung"></div>
                                        </div>
                                    </div> 
                                </b-form-group>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-12">
                            <div class="container">
                                <b-form-group
                                    label="Rekomendasi"
                                    label-for="name"
                                    class="mb-3"
                                >   
                                    <div class="card text-black bg-secondary mb-3">
                                        <div class="card-body">
                                            <div class="card-text" v-html="datarekomendasi.rekomendasi_data_pendukung"></div>
                                        </div>
                                    </div> 
                                </b-form-group>
                            </div>
                        </div>
                    </div>

                    <hr>
                    <div class="row">
                        <div class="col-md-12">
                        <h5> B. Percepatan Pelaksanaan Berusaha </h5>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-12 ml-4">
                        <b>  1. Peningkatan iklim berusaha melalui berbagai aktivitas penyederhanaan pelayanan perizinan berusaha</b>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-12">
                            <div class="container">
                                <b-form-group
                                    label="Resume Hasil Uji Petik Nomine"
                                    label-for="name"
                                    class="mb-3"
                                >
                                    <div class="card text-black bg-secondary mb-3">
                                        <div class="card-body">
                                            <div class="card-text" v-html="datarekomendasi.resume_peningkatan_iklim_berusaha"></div>
                                        </div>
                                    </div> 
                                </b-form-group>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-12">
                            <div class="container">
                                <b-form-group
                                    label="Rekomendasi"
                                    label-for="name"
                                    class="mb-3"
                                >
                                    <div class="card text-black bg-secondary mb-3">
                                        <div class="card-body">
                                            <div class="card-text" v-html="datarekomendasi.rekomendasi_peningkatan_iklim_berusaha"></div>
                                        </div>
                                    </div>    
                                </b-form-group>
                            </div>
                        </div>
                    </div>

                    <div class="row">
                        <div class="col-md-12 ml-4">
                        <b> 2. Tim pelaksana PPB di Kementerian Negara/Lembaga</b>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-12">
                            <div class="container">
                                <b-form-group
                                    label="Resume Hasil Uji Petik Nomine"
                                    label-for="name"
                                    class="mb-3"
                                >
                                    <div class="card text-black bg-secondary mb-3">
                                        <div class="card-body">
                                            <div class="card-text" v-html="datarekomendasi.resume_tim_pelaksana_ppb"></div>
                                        </div>
                                    </div> 
                                </b-form-group>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-12">
                            <div class="container">
                                <b-form-group
                                    label="Rekomendasi"
                                    label-for="name"
                                    class="mb-3"
                                >
                                    <div class="card text-black bg-secondary mb-3">
                                        <div class="card-body">
                                            <div class="card-text" v-html="datarekomendasi.rekomendasi_tim_pelaksana_ppb"></div>
                                        </div>
                                    </div> 
                                </b-form-group>
                            </div>
                        </div>
                    </div>

                    <div class="row">
                        <div class="col-md-12 ml-4">
                        <b> 3. Dukungan dan komitmen pimpinan pada upaya menciptakan ekosistem investasi yang kondusif</b>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-12">
                            <div class="container">
                                <b-form-group
                                    label="Resume Hasil Uji Petik Nomine"
                                    label-for="name"
                                    class="mb-3"
                                >
                                    <div class="card text-black bg-secondary mb-3">
                                        <div class="card-body">
                                            <div class="card-text" v-html="datarekomendasi.resume_dukungan_pimpinan"></div>
                                        </div>
                                    </div> 
                                </b-form-group>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-12">
                            <div class="container">
                                <b-form-group
                                    label="Rekomendasi"
                                    label-for="name"
                                    class="mb-3"
                                >
                                    <div class="card text-black bg-secondary mb-3">
                                        <div class="card-body">
                                            <div class="card-text" v-html="datarekomendasi.rekomendasi_dukungan_pimpinan"></div>
                                        </div>
                                    </div> 
                                </b-form-group>
                            </div>
                        </div>
                    </div>
                </form>
                <template #modal-footer>
                    <div class="w-100">
                    <b-button
                        variant="primary"
                        size="sm"
                        class="float-right"
                        style="background-color: #030A8C; border-color: #030A8C; color:white"
                        @click="$bvModal.hide('modal-editrekomendasikl')"
                    >
                        Tutup
                    </b-button>
                    </div>
                </template>
                </b-modal>
</div>
</template>
<script>
import Vue from 'vue';
import { mapGetters } from "vuex";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import axios from 'axios';
import vSelect from "vue-select";
import 'vue-select/dist/vue-select.css';
import Swal from "sweetalert2";
import VueLoading from 'vuejs-loading-plugin'
import { VueEditor } from "vue2-editor";

Vue.use(VueLoading)

// overwrite defaults
Vue.use(VueLoading, {
  dark: true, // default false
  text: 'Loading', // default 'Loading'
  loading: true, // default false
  //customLoader: myVueComponent, // replaces the spinner and text with your own
  background: 'rgb(255,255,255)', // set custom background
  classes: ['myclass'] // array, object or string
})

export default {
  components: {vSelect, VueEditor},
  data() {
    return {
    url: localStorage.getItem('baseapi'),
    token: localStorage.getItem('id_token'),
    user: JSON.parse(localStorage.getItem('user')),
    tanggal: localStorage.getItem('tanggal'),
    id:'',
    ujipetik:[],
    paparan:'',
    tahun: new Date().getFullYear(),
    setuju:'tidak',
    datarekomendasi:[],
    tahun: new Date().getFullYear(),
    };
  },

  mounted() {

    this.$store.dispatch(SET_BREADCRUMB, [{ title: "Uji Petik" }]);
    this.loaddata()
  },
  methods: {
    loaddata(){
        var api = ''
        if (this.user.id_role === 'ptsp_prov' || this.user.id_role === 'ptsp_provinsi') {
            api = this.url+'/tabel_nomine_provinsi?filter=tahun,=,'+this.tahun+';idptsp,=,'+this.user.id_ptsp
        } else if(this.user.id_role === 'ptsp_kabupaten'){
            api = this.url+'/tabel_nomine_kabupaten?filter=tahun,=,'+this.tahun+';idptsp,=,'+this.user.id_ptsp
        } else if(this.user.id_role === 'ptsp_kot' || this.user.id_role === 'ptsp_kota'){
            api = this.url+'/tabel_nomine_kota?filter=tahun,=,'+this.tahun+';idptsp,=,'+this.user.id_ptsp
        } else {
            api = this.url+'/tabel_nomine_kl?filter=tahun,=,'+this.tahun+';idkl,=,'+this.user.id_ptsp
        }
        axios.get(api, {
                  headers: {
                      'xth': this.token
                  }
              })
            .then(response => {
                this.ujipetik = response.data.data;
            }).catch(error=>{
                // localStorage.setItem('idpenilaian', 'undefined')
                console.log(error)
                return error
                // this.loaddata()
                });
        
    },
    persetujuan(idptsp){
        if (this.ujipetik.length === 0) {
            Swal.fire({
                title: 'Anda Tidak Bisa Akses Ini',
                text: "",
                icon: 'error',
                allowOutsideClick: false,
                showCancelButton: false,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'OK'
                }).then((result) => {
                    if (result.isConfirmed) {
                        this.$router.push({ name: "dashboard" })
                    }
                })
        } else {
            const swalWithBootstrapButtons = Swal.mixin({
            customClass: {
                confirmButton: 'btn btn-success',
                cancelButton: 'btn btn-danger'
            },
            buttonsStyling: false
            })

            swalWithBootstrapButtons.fire({
            // title: 'Are you sure?',
            showCloseButton: true,
            text: "Apakah anda telah memberikan informasi yang sesuai dengan fakta dilapangan kepada Tim Penilai dan bertanggang jawab informasi yang anda berikan?",
            icon: 'info',
            allowOutsideClick: false,
            showCancelButton: true,
            confirmButtonText: 'Ya',
            cancelButtonText: 'Tidak',
            reverseButtons: true
            }).then((result) => {
            if (result.isConfirmed) {
                axios.get(this.url+'/berita_acara/uji_petik?idpeserta='+idptsp, {
                        headers: {
                            'xth': this.token
                        }
                    })
                    .then(response => {
                        var data = response.data.data
                        console.log(data)
                        Swal.fire({
                            title: 'Konfirmasi selesai',
                            text: "",
                            icon: 'info',
                            allowOutsideClick: false, 
                        })
                        this.loaddata()
                    }).catch(error=>{
                        Swal.fire({
                            title: error.response.data.message,
                            text: "",
                            icon: 'error',
                            allowOutsideClick: false, 
                        })
                        console.log(error)
                            return error
                        });
            } else if (
                /* Read more about handling dismissals below */
                result.dismiss === Swal.DismissReason.cancel
            ) {
                this.setuju='tidak'
            }
            })
        }
    },
    cekba(){
      if (this.ujipetik[0].file_ba_uji_petik == null) {
            Swal.fire({
            title: "",
            text: "File BA Belum ada",
            icon: "info",
            confirmButtonClass: "btn btn-secondary"
            });
            this.loaddata()
        }else{
            window.open(this.ujipetik[0].file_ba_uji_petik, '_blank')
        }
			
    },
    cekrekomendasi(){
        var api = ''
        if (this.user.id_role === 'ppb_kl') {
            api = this.url+"/rekomendasi_tim_penilai_kl?filter=id_peserta,=,"+this.user.id_ptsp+";tahun,=,"+this.tahun
        } else {
            api = this.url+"/rekomendasi_tim_penilai?filter=id_peserta,=,"+this.user.id_ptsp+";tahun,=,"+this.tahun
        }
        axios.get(api, {
            headers: {
                'xth': this.token
            }
            })
        .then(response => {
            var data = response.data.data;
            if (data.length > 0) {
                this.datarekomendasi = response.data.data[0];
                if (this.user.id_role === 'ppb_kl') {
                    this.$bvModal.show('modal-editrekomendasikl')
                } else {
                    this.$bvModal.show('modal-editrekomendasi')
                }
            } else {
                 Swal.fire({
                    title: "",
                    text: "File Rekomendasi belum ada",
                    icon: "info",
                    confirmButtonClass: "btn btn-secondary"
                });
            }
        }).catch(error=>{
            return error
            });
			
    }
  },
  created(){
      
  }
//   destroyed() {
//     localStorage.removeItem("builderTab");
//   },
//   methods: {
//     /**
//      * Reset config
//      */
//     reset(event) {
//       event.preventDefault();
//       // remove existing saved config
//       localStorage.removeItem("config");
//       window.location.reload();
//     },

//     /**
//      * Set previous tab active
//      */
//     setActivePreviousTab() {
//       this.tabIndex = parseInt(localStorage.getItem("builderTab")) || 0;
//       const links = this.$refs["builder-tab"].querySelectorAll(".nav-link");
//       // remove active tab links
//       for (let i = 0; i < links.length; i++) {
//         links[i].classList.remove("active");
//       }
//       this.$refs["builder-tab"]
//         .querySelector(`[data-tab="${this.tabIndex}"]`)
//         .classList.add("active");
//     },
//     /**
//      * Set current active on click
//      * @param event
//      */
//     setActiveTab(event) {
//       const tab = event.target.closest('[role="tablist"]');
//       const links = tab.querySelectorAll(".nav-link");
//       // remove active tab links
//       for (let i = 0; i < links.length; i++) {
//         links[i].classList.remove("active");
//       }

//       // set clicked tab index to bootstrap tab
//       this.tabIndex = parseInt(event.target.getAttribute("data-tab"));

//       // set current active tab
//       event.target.classList.add("active");

//       // keep active tab
//       localStorage.setItem("builderTab", this.tabIndex);
//     },

//     /**
//      * Submit form
//      * @param event
//      */
//     submit(event) {
//       event.preventDefault();
//       // save new config to localStorage
//       localStorage.setItem("config", JSON.stringify(this.config));
//       window.location.reload();
//     }
//   }
};
</script>